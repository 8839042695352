<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <div class="d-print-none">
        <div class="d-print-none d-flex justify-content-end align-items-center mr-3">
          <span @click="handleClose" class="cursor-pointer" style="font-size:40px">&times;</span>
        </div>
        <div class="d-print-none">
          <h2 class="text-uppercase text-center">Chi tiết phiếu xuất</h2>
          <div class="container px-4">
            <div class="d-flex justify-content-end mb-2">
              <b-button
                @click="handleEditSaling"
                v-if="paymentInfo.value===PAYMENT_TYPE[0].value"
                class="mr-2"
                variant="primary"
              >Sửa Phiếu</b-button>
              <b-button
                @click="handlePrintBill"
                v-if="hasSalingForm"
                variant="primary"
              >In Phiếu Xuất</b-button>
              <b-button
                @click="handleSubmitSalingForm"
                v-if="!hasSalingForm&&paymentInfo.value===PAYMENT_TYPE[1].value"
                variant="primary"
              >Xuất Kho</b-button>
            </div>
            <div class="row match-height">
              <div class="col-lg-8">
                <b-card title="Thông tin khách hàng" class="cs-font">
                  <div class="row">
                    <div class="col-lg-6">
                      <span>Mã phiếu:</span>
                      <span
                        v-if="salingData.pharma_sale_code"
                        class="ml-1 cs-text-bold"
                      >{{ salingData.pharma_sale_code}}</span>
                    </div>
                    <div class="col-lg-6">
                      <span>Họ và tên:</span>
                      <span class="ml-1 cs-text-bold">{{ salingData.customer_name}}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-lg-6">
                      <span>Số điện thoại:</span>
                      <span class="ml-1 cs-text-bold">{{ salingData.customer_phone}}</span>
                    </div>
                    <div class="col-lg-3">
                      <span>Tuổi:</span>
                      <span class="ml-1 cs-text-bold">{{ Number(salingData.customer_age)||''}}</span>
                    </div>
                    <div class="col-lg-3">
                      <span>Giới tính:</span>
                      <span
                        v-if="salingData.customer_gender"
                        class="ml-1 cs-text-bold"
                      >{{ salingData.customer_gender===1?'Nam':'Nữ'}}</span>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-lg-6">
                      <span>Địa chỉ:</span>
                      <span class="ml-1 cs-text-bold">{{ salingData.customer_address}}</span>
                    </div>
                    <div class="col-lg-6">
                      <span>Nơi chỉ định:</span>
                      <span class="ml-1 cs-text-bold">{{ salingData.prescribed_clinic}}</span>
                    </div>
                  </div>
                </b-card>
              </div>
              <div class="col-lg-4">
                <b-card title="Thanh toán" class="cs-font">
                  <div class="d-flex">
                    <span>Tổng tiền:</span>
                    <span
                      style="flex:1"
                      v-if="salingData.total_amount"
                      class="ml-1 cs-text-bold text-right"
                    >{{ formatPrice(salingData.total_amount) }}</span>
                  </div>
                  <!-- <div class="d-flex align-items-center">
                  <span> Tổng tiền bằng chữ:</span>
                  <span v-if="salingData.total_amount" class="ml-1 cs-capitalize">
                    <p class="m-0">{{ `${appUtils.numberToWords(salingData.total_amount)}` }}</p>
                  </span>
                  </div>-->

                  <!-- SELECT -->
                  <div class="d-flex mt-1 justify-content-between align-items-end">
                    <span>Trạng thái:</span>
                    <div class="ml-1 d-flex flex-1 justify-content-end">
                      <div
                        v-click-outside="handleCloseDropDown"
                        class="cs-relative"
                        style="width:100%"
                      >
                        <div
                          @click="handleToggleDropdown"
                          class="d-flex align-items-center justify-content-center"
                          :class="{
                        'not-payment cursor-pointer justify-content-between':paymentInfo.value===PAYMENT_TYPE[0].value,
                        'payment':paymentInfo.value===PAYMENT_TYPE[1].value,
                        'reject':paymentInfo.value===PAYMENT_TYPE[2].value,
                        'status':true,
                        }"
                        >
                          <div>{{paymentInfo.label }}</div>
                          <feather-icon
                            v-if="paymentInfo.value===PAYMENT_TYPE[0].value"
                            icon="ChevronDownIcon"
                            size="20"
                            class="text-white ml-1"
                          />
                        </div>
                        <div v-if="isOpenDropdown" class="menu-select">
                          <div
                            @click="handleSelectPaymentType(item)"
                            class="cs-menu-item"
                            :class="{'cs-item-active':item.value===paymentInfo.value}"
                            v-for="item in PAYMENT_TYPE"
                            :key="item.value"
                          >
                            <span>{{ item.label }}</span>
                            <feather-icon
                              v-if="item.value===paymentInfo.value"
                              icon="CheckIcon"
                              size="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- SELECT -->
                  <!-- <div v-if="paymentInfo.value===PAYMENT_TYPE[1].value" class="mt-1">
                  <div class="d-flex justify-content-between">
                    <span>Người thanh toán:</span>
                    <span>
                      <strong>Nguyễn Minh Quang</strong>
                    </span>
                  </div>
                  </div>-->
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[1].value" class>
                    <div class="d-flex justify-content-end">
                      <span>
                        <i style="font-size:12px">
                          (
                          <span
                            v-if="receiptData.collect_user"
                          >{{ `${receiptData.collect_user.name} ` }}</span>
                          <span>{{ formatDate(receiptData.collected_at) }}</span>
                          )
                        </i>
                      </span>
                    </div>
                  </div>
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[1].value" class="mt-2">
                    <div class="d-flex">
                      <b-button
                        @click="handlePrintReceipts"
                        class="w-full"
                        variant="outline-primary"
                      >In Phiếu Thu</b-button>
                    </div>
                  </div>
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[2].value" class>
                    <div class="d-flex justify-content-end">
                      <span>
                        <i style="font-size:12px">
                          (
                          <span
                            v-if="receiptData.cancel_user"
                          >{{ `${receiptData.cancel_user.name} ` }}</span>
                          <span>{{ formatDate(receiptData.cancelled_at) }}</span>
                          )
                        </i>
                      </span>
                    </div>
                  </div>
                  <div v-if="paymentInfo.value===PAYMENT_TYPE[2].value" class="mt-1">
                    <div class="d-flex justify-content-between">
                      <span>Lý do:</span>
                      <span>
                        <strong>{{ rejectReason }}</strong>
                      </span>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
            <div>
              <b-card>
                <h3>Danh sách Thuốc</h3>
                <b-overlay
                  :show="false"
                  rounded="sm"
                  spinner-variant="primary"
                  spinner-type="grow"
                  spinner-small
                >
                  <b-table-simple class="cs-table" bordered responsive small>
                    <colgroup>
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                      <col />
                    </colgroup>

                    <b-thead>
                      <b-tr>
                        <b-td
                          v-for="(item,index) in tableColumnsPreview"
                          :key="index"
                          class="font-bold"
                          rowspan="2"
                          colspan="1"
                        >
                          <div>{{ item.label }}</div>
                        </b-td>
                      </b-tr>
                    </b-thead>
                    <b-tbody v-for="(item,index) in medicines" :key="`${index}-${item.id}`">
                      <b-tr>
                        <b-th colspan="12">
                          {{ `${item.product_info.name} `}}
                          <span
                            v-if="item.shortage"
                          >{{ `(Thiếu ${item.shortage} trên ${item.request_qty} ${getUnit(item)})` }}</span>
                        </b-th>
                      </b-tr>
                      <b-tr v-for="(child,i) in item.data" :key="i">
                        <b-td>{{i+1}}</b-td>
                        <!-- <b-td>{{ child.product_info.name}}</b-td> -->
                        <b-td>{{ child.product_info.quality }}</b-td>
                        <b-td>{{ child.qty }}</b-td>
                        <b-td>{{ getUnit(child) }}</b-td>
                        <b-td>{{(child.product_info.lot_number)||'' }}</b-td>
                        <b-td>{{formatMoment(child.product_info)||'' }}</b-td>
                        <b-td>{{(child.product_info.registration_number)||'' }}</b-td>
                        <b-td>{{ appUtils.numberFormat(child.product_info.price_in) }}</b-td>
                        <b-td>{{ appUtils.numberFormat(child.product_info.price_out) }}</b-td>
                        <b-td style="width:8%">{{(child.product_info.vat_in)||'' }}</b-td>
                        <b-td style="width:8%">{{(child.product_info.vat_out)||'' }}</b-td>
                        <b-td class="text-right">{{ appUtils.numberFormat(child.total_price) }}</b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tfoot>
                      <b-tr>
                        <b-th
                          colspan="11"
                          class="text-right"
                          style="font-weight:500;font-size:14px"
                        >Tổng tiền</b-th>
                        <b-th
                          class="text-right"
                          style="font-size:14px"
                        >{{ appUtils.numberFormat(salingData.total_amount) }}</b-th>
                      </b-tr>
                    </b-tfoot>
                  </b-table-simple>
                </b-overlay>
              </b-card>
            </div>
          </div>
        </div>
      </div>
      <b-card v-if="isPrintBill" class="cs-print">
        <div class="container cs-container">
          <div ref="documentPrint" id="documentPrint" class="h-full">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-6 text-center font-weight-bold"></div>
              <div class="col-4 d-flex justify-content-end cs-text-bold">
                <span>Mã phiếu:</span>
                <span class="ml-1">{{ salingData.pharma_sale_code}}</span>
              </div>
            </div>
            <div class="text-center mt-2">
              <h2 class="text-uppercase">Phiếu xuất</h2>
              <h4 v-if="salingData.inventory_info" class="m-0">{{ salingData.inventory_info.name }}</h4>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <span>Họ tên:</span>
                <span class="ml-1 cs-text-bold">{{ salingData.customer_name }}</span>
              </div>
              <div class="col-3">
                <span>Tuổi:</span>
                <span class="ml-1 cs-text-bold">{{ Number(salingData.customer_age)||'' }}</span>
              </div>
              <div class="col-3">
                <span>Giới tính:</span>
                <span class="ml-1 cs-text-bold">{{ salingData.customer_gender===1?'Nam':'Nữ' }}</span>
              </div>
            </div>
            <div class>
              <span>Số điện thoại:</span>
              <span class="ml-1 cs-text-bold">{{ salingData.customer_phone }}</span>
            </div>
            <div class>
              <span>Địa chỉ:</span>
              <span class="ml-1 cs-text-bold">{{ salingData.customer_address }}</span>
            </div>
            <div class>
              <span>Nơi chỉ định:</span>
              <span class="ml-1 cs-text-bold">{{ salingData.prescribed_clinic }}</span>
            </div>
            <b-overlay
              :show="false"
              rounded="sm"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
            >
              <b-table-simple class="cs-saling" bordered responsive small>
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>

                <b-thead>
                  <b-tr>
                    <b-td
                      v-for="(item,index) in tableColumnsPreview"
                      :key="index"
                      class="font-bold"
                      rowspan="2"
                      colspan="1"
                    >
                      <div>{{ item.label }}</div>
                    </b-td>
                  </b-tr>
                </b-thead>
                <b-tbody v-for="(item,index) in medicines" :key="`${index}-${item.id}`">
                  <b-tr>
                    <b-th colspan="12">
                      {{ `${item.product_info.name} `}}
                      <span
                        v-if="item.shortage"
                      >{{ `(Thiếu ${item.shortage} trên ${item.request_qty} ${getUnit(item)})` }}</span>
                    </b-th>
                  </b-tr>
                  <b-tr v-for="(child,i) in item.data" :key="i">
                    <b-td>{{i+1}}</b-td>
                    <b-td>{{ child.product_info.quality }}</b-td>
                    <b-td>{{ child.qty }}</b-td>
                    <b-td>{{ getUnit(child) }}</b-td>
                    <b-td>{{(child.product_info.lot_number)||'' }}</b-td>
                    <b-td>{{formatMoment(child.product_info)||'' }}</b-td>
                    <b-td>{{(child.product_info.registration_number)||'' }}</b-td>
                    <b-td>{{ appUtils.numberFormat(child.product_info.price_in) }}</b-td>
                    <b-td>{{ appUtils.numberFormat(child.product_info.price_out) }}</b-td>
                    <b-td style="width:8%">{{(child.product_info.vat_in)||'' }}</b-td>
                    <b-td style="width:8%">{{(child.product_info.vat_out)||'' }}</b-td>
                    <b-td class="text-right">{{ appUtils.numberFormat(child.total_price) }}</b-td>
                  </b-tr>
                </b-tbody>
                <b-tfoot>
                  <b-tr>
                    <b-th colspan="11" class="text-right" style="font-weight:500">Tổng tiền</b-th>
                    <b-th class="text-right">{{ appUtils.numberFormat(salingData.total_amount) }}</b-th>
                  </b-tr>
                </b-tfoot>
              </b-table-simple>
            </b-overlay>
            <div class="cs-text-bold mt-3">
              <div>
                <span>- Tổng tiền:</span>
                <span
                  v-if="salingData.total_amount"
                  class="ml-1"
                >{{ formatPrice(salingData.total_amount) }}</span>
              </div>
              <div class="d-flex align-items-center">
                <span>- Tổng tiền bằng chữ:</span>
                <span v-if="salingData.total_amount" class="ml-1 cs-capitalize">
                  <p class="m-0">{{ `${appUtils.numberToWords(salingData.total_amount)}` }}</p>
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4"></div>
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <div class="italic">
                  <i>{{ formatDate(salingData.sale_date) }}</i>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">Người nhận</div>
                  <div>
                    <i>(Ký, họ tên)</i>
                  </div>
                  <div
                    v-if="salingData.customer_name"
                    style="margin-top:65px"
                    class="text-uppercase"
                  >{{ salingData.customer_name}}</div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">Người giao</div>
                  <div
                    v-if="userLocal.name"
                    class="text-uppercase"
                    style="margin-top:95px"
                  >{{ userLocal.name}}</div>
                </div>
              </div>
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">Thu ngân</div>
                  <div class="text-center">
                    <i>(Ký, họ tên)</i>
                  </div>
                  <div
                    class="text-uppercase"
                    v-if="receiptData.collect_user"
                    style="margin-top:65px"
                  >{{ receiptData.collect_user.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
      <b-card v-if="isPrintReceipts" class="cs-print">
        <div class="container cs-container">
          <div ref="documentPrint" id="documentPrint" class="h-full">
            <div class="row">
              <div class="col-2"></div>
              <div class="col-6 text-center font-weight-bold"></div>
              <div class="col-4 d-flex justify-content-end cs-text-bold">
                <span>Mã phiếu:</span>
                <span class="ml-1">{{ receiptData.pharma_receipt_code}}</span>
              </div>
            </div>
            <div class="text-center mt-2">
              <h2 class="text-uppercase">Phiếu thu</h2>
              <h4 v-if="salingData.inventory_info" class="m-0">{{ salingData.inventory_info.name }}</h4>
            </div>
            <div class="row mt-2">
              <div class="col-6">
                <span>Họ tên:</span>
                <span class="ml-1 cs-text-bold">{{ receiptData.customer_name }}</span>
              </div>
              <div class="col-3">
                <span>Tuổi:</span>
                <span class="ml-1 cs-text-bold">{{ receiptData.customer_age||"" }}</span>
              </div>
              <div class="col-3">
                <span>Giới tính:</span>
                <span class="ml-1 cs-text-bold">{{ receiptData.customer_gender===1?'Nam':'Nữ' }}</span>
              </div>
            </div>
            <div class>
              <span>Số điện thoại:</span>
              <span class="ml-1 cs-text-bold">{{ receiptData.customer_phone }}</span>
            </div>
            <div class>
              <span>Địa chỉ:</span>
              <span class="ml-1 cs-text-bold">{{ receiptData.customer_address }}</span>
            </div>
            <div class>
              <span>Nơi chỉ định:</span>
              <span class="ml-1 cs-text-bold">{{ receiptData.prescribed_clinic }}</span>
            </div>
            <b-overlay
              :show="false"
              rounded="sm"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
            >
              <b-table
                small
                striped
                bordered
                outlined
                class="cs-btable mt-2"
                responsive
                :items="medicinesPrint"
                :fields="tableColumns"
              >
                <template #cell(index)="data">
                  <span class="text-capitalize">{{ data.index+1 }}</span>
                </template>
                <template #cell(unit_id)="data">
                  <span class="text-capitalize">{{getUnit(data.item) }}</span>
                </template>
                <template #cell(price)="data">
                  <span class="text-capitalize">{{appUtils.numberFormat(data.item.realPrice) }}</span>
                </template>
                <template #cell(total_price)="data">
                  <span class="text-capitalize">{{appUtils.numberFormat(data.item.total_price) }}</span>
                </template>
                <template #bottom-row>
                  <b-th colspan="6" class="text-right" style="font-weight:500">Tổng tiền</b-th>
                  <b-th
                    class="text-right"
                    style="font-weight:500"
                  >{{ appUtils.numberFormat(salingData.total_amount) }}</b-th>
                </template>
              </b-table>
            </b-overlay>
            <div class="cs-text-bold mt-3">
              <div>
                <span>- Tổng tiền:</span>
                <span
                  v-if="salingData.total_amount"
                  class="ml-1"
                >{{ formatPrice(salingData.total_amount) }}</span>
              </div>
              <div class="d-flex align-items-center">
                <span>- Tổng tiền bằng chữ:</span>
                <span v-if="salingData.total_amount" class="ml-1 cs-capitalize">
                  <p class="m-0">{{ `${appUtils.numberToWords(salingData.total_amount)}` }}</p>
                </span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4"></div>
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <div class="italic">
                  <i>{{ formatDate(receiptData.receipt_date) }}</i>
                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">Người nhận</div>
                  <div>
                    <i>(Ký, họ tên)</i>
                  </div>
                  <div
                    v-if="receiptData.customer_name"
                    style="margin-top:65px"
                    class="text-uppercase"
                  >{{ receiptData.customer_name}}</div>
                </div>
              </div>
              <div class="col-4 text-center">
                <!-- <div>
                  <div class="cs-text-bold">Người giao</div>
                </div>
                <div
                  v-if="userLocal.name"
                  class="text-uppercase"
                  style="margin-top:95px"
                >{{ userLocal.name}}</div>-->
              </div>
              <div class="col-4 text-center">
                <div>
                  <div class="cs-text-bold">Thu ngân</div>
                  <div class="text-center">
                    <i>(Ký, họ tên)</i>
                  </div>
                  <div
                    class="text-uppercase"
                    v-if="receiptData.collect_user"
                    style="margin-top:65px"
                  >{{ receiptData.collect_user.name}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="isShowModalReject"
      title="Huỷ phiếu"
      cancel-title="Huỷ bỏ"
      ok-title="Xác nhận"
      centered
      @ok="handleRejectBill"
    >
      <validation-observer ref="rejectModal">
        <validation-provider #default="{ errors }" name="Tên Khách hàng" rules="required">
          <b-form-input v-model="rejectReason" placeholder="Nhập lý do huỷ phiếu" />

          <small class="text-danger">{{ errors[0] ? 'Bạn cần nhập lý do huỷ phiếu' : '' }}</small>
        </validation-provider>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import { PAYMENT_TYPE, SALING_STATUS } from "@/utils/constant";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

const tableColumnsPreview = [
  { key: "index", label: "STT" },
  // {
  //   key: "product_info.name",
  //   label: "Tên thuốc"
  // },
  { key: "product_info.quality", label: "Hàm lượng" },
  { key: "qty", label: "Số lượng", class: "text-center" },
  { key: "unit_id", label: "Đơn vị" },
  {
    key: "product_info.lot_number",
    label: "Số lô"
  },
  {
    key: "expried_date",
    label: "Hạn sử dụng"
  },
  {
    key: "product_info.registration_number",
    label: "SĐK"
  },
  {
    key: "price_in",
    label: "Giá nhập",
    class: "text-right"
  },
  {
    key: "price_out",
    label: "Giá bán",
    class: "text-right"
  },
  {
    key: "product_info.vat_in",
    label: "VAT nhập(%)"
  },
  {
    key: "product_info.vat_out",
    label: "VAT bán(%)"
  },
  {
    key: "total_price",
    label: "Thành tiền",
    class: "text-right"
  }
];

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "product_info.name",
    label: "Tên thuốc",
    sortable: false
  },
  { key: "product_info.quality", label: "Hàm lượng", sortable: false },
  { key: "qty", label: "Số lượng", sortable: false, class: "text-center" },
  { key: "unit_id", label: "Đơn vị", sortable: false },
  // {
  //   key: "product_info.lot_number",
  //   label: "Số lô",
  //   sortable: false
  // },
  {
    key: "price",
    label: "Đơn giá (đã VAT)",
    class: "text-right"
  },
  {
    key: "total_price",
    label: "Thành tiền",
    sortable: false,
    class: "text-right"
  }
];

export default {
  name: "SalingDetail",
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      medicines: [],
      salingData: {},
      appUtils,
      tableColumns,
      PAYMENT_TYPE,
      paymentInfo: {},
      isOpenDropdown: false,
      isPrintBill: false,
      isPrintReceipts: false,
      isShowModalReject: false,
      rejectReason: "",
      required,
      hasSalingForm: false,
      isLoading: false,
      receiptData: {},
      tableColumnsPreview,
      medicinesPrint: []
    };
  },
  computed: {
    userLocal() {
      return JSON.parse(localStorage.getItem("user"));
    }
  },
  watch: {
    $route() {
      this.handleViewDetail();
    }
  },
  async created() {
    await this.handleViewDetail();
  },
  methods: {
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    handlePrintBill() {
      this.isPrintReceipts = false;
      this.isPrintBill = true;

      setTimeout(() => {
        window.print();
      }, 500);
    },
    handlePrintReceipts() {
      this.isPrintBill = false;

      this.isPrintReceipts = true;

      setTimeout(() => {
        window.print();
      }, 500);
    },
    handleClose() {
      this.$router.go(-1);
    },
    async handleViewDetail() {
      try {
        const response = await this.$store.dispatch("saling/getSalingDetail", {
          id: this.$route.params.id
        });
        this.medicinesPrint = (response.data?.sale_details || [])?.map(item => {
          // const realPrice =
          //   item?.sale_price * (1 + item?.product_info?.vat_out / 100);
          const roundPrice = Math.round((item?.sale_price * 100) / 100);
          return {
            ...item,
            realPrice: roundPrice,
            total_price: (roundPrice || 0) * (item?.qty || 0)
          };
        });

        const totalPrice = this.medicinesPrint?.reduce(
          (total, item) => total + (item?.total_price || 0),
          0
        );
        const groupMedicines = this.handleGroupMedicines([
          ...this.medicinesPrint
        ]);

        this.medicines = groupMedicines;

        this.salingData = { ...response.data, total_amount: totalPrice } || {};
        this.receiptData = response.data?.pharma_receipt || {};
        const paymentStatus = response.data?.pharma_receipt?.payment_status;
        this.hasSalingForm =
          response.data?.pharma_sale_status === SALING_STATUS.sold;

        if (paymentStatus) {
          this.paymentInfo = PAYMENT_TYPE.find(
            item => item.value === paymentStatus
          );
        } else {
          this.paymentInfo = PAYMENT_TYPE[0];
        }

        this.rejectReason = this.receiptData?.cancelled_reason;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    handleGroupMedicines(dataList) {
      const groupMedicines = dataList?.reduce((list, item) => {
        const index = list.findIndex(
          itemIndex =>
            itemIndex?.product_inventory_info?.product_master_id ===
            item?.product_inventory_info?.product_master_id
        );
        let totalOrders = 0;
        let shortage = 0;
        if (index === -1) {
          totalOrders = item.qty;
          shortage = item.shortage;
          list.push({ ...item, totalOrders, data: [item] });
        } else {
          shortage = list[index].shortage + item.shortage;
          totalOrders = list[index].totalOrders + item.qty;
          list[index].totalOrders = totalOrders;
          list[index].shortage = shortage;
          list[index].data = list[index].data || [];
          list[index].data.push(item);
        }

        return list;
      }, []);

      return groupMedicines;
    },
    formatPrice(price) {
      return appUtils.numberFormat(Number(price), "VNĐ");
    },
    getUnit(data) {
      return data?.product_info?.unit_info?.name || "";
    },
    formatDate(timestamp) {
      return window.moment(timestamp).format("HH:mm-DD/MM/YYYY");
    },
    getAge(value) {
      return (
        value?.birthday &&
        (window.moment().diff(value.birthday, "years", false)
          ? window.moment().diff(value.birthday, "years", false)
          : "")
      );
    },
    getGender(value) {
      return value?.gender === 1 ? "Nam" : value?.gender === 2 ? "Nữ" : "";
    },
    getAddress(data) {
      let result = "";
      if (data.person_info?.address) {
        result += data.person_info?.address;
      }
      if (data.person_info?.address_district_name) {
        result = result + " " + data.person_info?.address_district_name;
      }
      if (data.person_info?.address_province_name) {
        result = result + " " + data.person_info?.address_province_name;
      }

      return result;
    },
    handleEditSaling() {
      if (!this.$route.params.id) return;

      this.$router.replace({
        name: "UpdateSaling",
        params: { id: this.$route.params.id }
      });
    },
    handleRejectBill(e) {
      e.preventDefault();

      this.$refs.rejectModal.validate().then(success => {
        if (success) {
          this.isShowModalReject = false;
          this.handleUpdateStatus(PAYMENT_TYPE[2].value);
        } else {
          return;
        }
      });
    },
    handleSelectPaymentType(option) {
      this.isOpenDropdown = false;

      if (option.value === PAYMENT_TYPE[2].value) {
        this.isShowModalReject = true;

        return;
      }

      let label;
      if (option.value === this.paymentInfo.value) return;

      if (option.value === PAYMENT_TYPE[1].value) {
        const isValid = this.handleCheckMedicine();
        if (!isValid) return;
      }

      if (option.value === PAYMENT_TYPE[0].value) {
        label = "Bạn có muốn đổi sang trạng thái chưa thanh toán không?";
      } else {
        label = "Bạn có muốn đổi sang trạng thái đã thanh toán không?";
      }

      this.$bvModal
        .msgBoxConfirm(label, {
          title: "Xác nhận trạng thái",
          size: "sm",
          okVariant: "primary",
          okTitle: "Xác nhận",
          cancelTitle: "Huỷ",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            // this.paymentInfo = option;
            this.handleUpdateStatus(option.value);
            return;
          } else {
            return;
          }
        });
    },
    handleCloseDropDown() {
      this.isOpenDropdown = false;
    },
    handleToggleDropdown() {
      // this.isOpenDropdown = !this.isOpenDropdown;

      if (this.paymentInfo.value === PAYMENT_TYPE[0].value) {
        this.isOpenDropdown = !this.isOpenDropdown;
      }
    },
    async handleUpdateStatus(status) {
      if (!this.salingData.id) return;

      try {
        this.isLoading = true;

        const params = {
          payment_status: status,
          pharma_sale_id: this.salingData.id
        };

        if (status === PAYMENT_TYPE[1].value) {
          const listMedicines = this.medicines?.map(item => {
            return {
              inventory_id: item?.pharma_inventory_id,
              product_master_id:
                item?.product_inventory_info?.product_master_id,
              request_amount: item?.totalOrders
            };
          });

          params.validate_amounts = listMedicines;
        }

        if (status === PAYMENT_TYPE[2].value) {
          params.cancelled_reason = this.rejectReason;
        }

        await this.$store.dispatch("saling/updatePaymentSaling", params);

        await this.handleViewDetail();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Cập nhật trạng thái thành công",
            icon: "CheckCircleIcon",
            variant: "success"
          }
        });
      } catch (error) {
        if (error.response.status === 400) {
          const dataError = error.response?.data;
          const messageErrorList = dataError?.map(item => {
            return `${item.pharma_name} thiếu ${item.shortage} đơn vị`;
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Đổi trạng thái không thành công do ${messageErrorList.join(
                " ,"
              )} trong kho`,
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi cập nhật trạng thái",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmitSalingForm() {
      if (!this.salingData.id) return;

      try {
        this.isLoading = true;

        const params = {
          id: this.salingData.id,
          pharma_sale_status: SALING_STATUS.sold
        };
        const listMedicines = this.medicines?.map(item => {
          return {
            inventory_id: item?.pharma_inventory_id,
            product_master_id: item?.product_inventory_info?.product_master_id,
            request_amount: item?.totalOrders
          };
        });

        params.validate_amounts = listMedicines;

        await this.$store.dispatch("saling/updateSalingStatus", params);

        await this.handleViewDetail();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Xuất kho thành công",
            icon: "CheckCircleIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.log(error);
        if (error.response.status === 400) {
          const dataError = error.response?.data;
          const messageErrorList = dataError?.map(item => {
            return `${item.pharma_name} thiếu ${item.shortage} đơn vị`;
          });

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Xuất kho không thành công do ${messageErrorList.join(
                " ,"
              )} trong kho`,
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Có lỗi xảy ra khi cập nhật trạng thái",
              icon: "AlertTriangleIcon",
              variant: "danger"
            }
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    handleCheckMedicine() {
      // const isAllAmount = this.medicines?.every(
      //   item =>
      //     item?.product_inventory_info?.qty &&
      //     Number(item?.product_inventory_info?.qty) >= item?.qty
      // );

      // if (!isAllAmount) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: "Vui lòng nhập đủ số lượng và không được vượt quá khả dụng",
      //       icon: "AlertTriangleIcon",
      //       variant: "danger"
      //     }
      //   });

      //   return false;
      // }

      const listExpiredDate = this.medicinesPrint
        .filter(item => {
          const expiredDate = this.formatMoment(item?.product_info);
          const formatExpiredDate = this.convertDateFormat(expiredDate);
          const formatDate = moment(formatExpiredDate)
            .utc()
            .unix();
          const currentDate = moment()
            .utc()
            .unix();

          // console.log(formatDate);
          // console.log(currentDate);

          return !formatDate || currentDate > formatDate;
        })
        ?.map(item => item?.product_info?.name);
      const dataList = [...new Set(listExpiredDate)];
      if (dataList.length > 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Bạn không thể tạo phiếu xuất do ${dataList.join(
              ","
            )} đã quá hạn sử dụng`,
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });

        return false;
      }

      return true;
    },
    formatMoment(data) {
      if (
        !Number(data?.expiry_date_day) &&
        !Number(data?.expiry_date_month) &&
        !Number(data?.expiry_date_year)
      )
        return "";

      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "01/";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "01/";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    },
    convertDateFormat(dateString) {
      if (!dateString) return "";
      var parts = dateString.split("/");
      return parts[2] + "-" + parts[1] + "-" + parts[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  background-color: #fff;
  min-height: calc(100vh - 1px);
  max-width: 1000px;
  overflow: hidden;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-table {
    width: 1000px;
    margin-top: 16px;
    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }
  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-text-bold {
  font-weight: 600;
}

.cs-close {
  display: block;
  position: absolute;
  top: -50px;
  right: 40px;
  // height: 30px;
  // width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

.cs-font * {
  font-size: 16px;

  h4 {
    font-size: 20px;
  }
}

.not-payment ::v-deep {
  background-color: rgb(243, 129, 8) !important;
  color: white !important;
}

.reject ::v-deep {
  color: white !important;
  background-color: red !important;
}

.payment ::v-deep {
  color: white !important;
  background-color: #17ad73 !important;
}

.status {
  padding: 8px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 4px;
  font-size: 16px;
  user-select: none;
  width: 100%;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.menu-select {
  background-color: #fff;
  position: absolute;
  bottom: -130px;
  right: 0;
  z-index: 2;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.cs-saling {
  max-width: 1000px;
}

.cs-menu-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;

  &:hover {
    background-color: rgba(21, 76, 205, 0.1);
  }

  span {
    display: block;
    // width: 100%;
    // height: 100%;
  }
}

.cs-item-active {
  background-color: #20419b !important;
  color: white;
}
::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 20px;
    text-transform: none !important;
  }

  tbody {
    font-size: 20px;
  }
}
.cs-print {
  display: none !important;
  margin: 0 !important;
}

.cs-print * {
  font-size: 20px;
}

@media print {
  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }
}
</style>